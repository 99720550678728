.footer-container {
    border-top: 1px solid black;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 0;
}

.footer-1 {
   display: grid; 
   grid-template-columns: 1fr 1fr 1fr;
   width: 50%;
   margin: auto;
   gap: 20px;
   text-align: center;
}

.footer-1 h5 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.footer-1 p {
    margin: 10px;
    font-family: 'Space Grotesk', sans-serif;
}

.footer-bottom p {
    margin: 0;
    padding-bottom: 40px;
    margin-top: 40px;
    font-family: 'Space Grotesk', sans-serif;
    text-align: center;
}


