.navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    border-bottom: 1.5px solid black;
    display: flex;
    justify-content: flex-end;
    z-index: 100;
    background-color: #fdfaf0;
}

.right {
    display: flex;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 20px; /* Increase this to 20px, 24px, etc. */
    font-weight: 700; /* 400 = normal, 700 = bold */
}

.nav-item {
    padding: 10px 20px;
    border-left: 1.5px solid black;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.nav-item:hover {
    background-color: #f0f0f0;
}

.nav-item {
    color: black;
    text-decoration: none;
}