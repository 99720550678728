html {
  scroll-behavior: smooth;
}

.background {
  background-color: #fdfaf0;
  width: 100%;
  min-height: 100vh;
}

.hero {
  font-family: 'Space Grotesk', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.hero h2 {
  margin-top: 0px;
  margin-left: 10%;
  font-size: 50px; /* Increase this to 20px, 24px, etc. */
  font-weight: 700; /* 400 = normal, 700 = bold */
  margin-bottom: 0px;
}

.hero h1 {
  margin-left: 10%;
  font-size: 100px; /* Increase this to 20px, 24px, etc. */
  font-weight: 700; /* 400 = normal, 700 = bold */
  margin-top: 0px;
  margin-bottom: 0px;
}

.hero p {
  margin-left: 10%;
  font-size: 35px; /* Increase this to 20px, 24px, etc. */
  margin-top: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
}
.scroll-indicator {
  font-size: 50px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 1.5s infinite;
  color: #333;
  bottom: 50px;
  text-decoration: none;
}

@keyframes bounce {
  0%, 100% { transform: translate(-50%, 0); }
  50% { transform: translate(-50%, -10px); }
}

.hero h1, .hero h2, .hero p {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;
}

.hero h2 {
  animation-delay: 0.1s;
}
.hero h1 {
  animation-delay: 0.3s;
}
.hero p {
  animation-delay: 0.5s;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


/* Make sure the card doesn’t stretch weird */
.project-card {
  width: 100%;
  max-width: 350px;
  background-color: #fff;
  border: 1px solid black;
  border-radius: 20px;
}

.project-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 12px rgba(0,0,0,0.1);
  transition: all 0.2s ease-in-out;
}

/* Projects */
.projects {
  background-color: #fdfaf0;
  font-family: 'Space Grotesk', sans-serif;
}

 h4 {
  font-size: 50px; /* Increase this to 20px, 24px, etc. */
  font-weight: 700; /* 400 = normal, 700 = bold */
  margin-left: 15%;
  margin-bottom: 0px;
  margin-top: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 70%;
  gap: 40px;
  margin: auto;
  align-items: start; 
}

/* project card */

.project-card {
  border: 1px solid black;
  border-radius: 20px;
  overflow: hidden;
  
}

.project-card img {
  height: 300px;
  max-width: 100%;
  object-fit: cover;
  margin: 0;
  border-bottom: 1px solid black;
  min-width: 100%;
}

.project-card h3 {
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 0px;
}

.project-card .tech-stack {
  margin-top: 0px;
  padding-top: 2px;
  margin-left: 10px;
  border-bottom: 1px solid black;
  width: auto;
  margin-right: 0;
  margin-bottom: 10px;
  display: inline-block;
}

.project-card .desc {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 0px;
  font-size: 14px; /* Increase this to 20px, 24px, etc. */
  font-weight: 100; /* 400 = normal, 700 = bold */
  line-height: 1.5;
}

/* Multiple Links */


.project-card .project-links {
  border-top: 1px solid black;
  text-align: center;
  color: black;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.project-links div {
 padding-top: 5px;
 padding-bottom: 5px;
 transition: all 0.2s ease;
}


.project-links a {
  color: black;
  cursor: pointer;
}

.project-links div:hover {
  background-color: #f0f0f0;
}



.project-card .single-link {
  border-top: 1px solid black;
  text-align: center;
  color: black;
}

.single-link div {
 padding-top: 5px;
 padding-bottom: 5px;
 transition: all 0.2s ease;
}


.single-link a {
  color: black;
  cursor: pointer;
}

.single-link div:hover {
  background-color: #f0f0f0;
}

/* Filter */

.filter {
  font-size: 20px;
  display: flex;
  margin: 20px 15%;
  margin-bottom: 30px;
}

.bold {
  font-weight: 700;
}

.filter p {
  margin: 0px 15px;
  cursor: pointer;
}

.underline {
  border-bottom: 1px solid black;
}